<template>
  <div v-if="$canAndRulesPass(user, 'setting.back_integration')">
    <page-heading
      heading="Результаты интеграции"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


    <div style="width: 50%">
      <el-table
        style="width: 100%"
        :data="integrations"
        empty-text="Нет истории"
        v-loading="loading"
        size="mini"
      >
        <el-table-column prop="created_at" label="Дата запуска" align="center">
          <template slot-scope="scope">
            {{ new Date(scope.row.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}
          </template>
        </el-table-column>
        <el-table-column label="Состояние" align="center">
          <template slot-scope="scope">
            {{ $companyConfiguration.settings.getEnum('back_integration_statuses')[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="" align="center">
          <template slot-scope="scope">
            Ошибок: {{scope.row.result ? scope.row.result.errors_count : 'Нет данных'}}
            <el-button
              style="margin-left:40px"
              size="small"
              @click="showDetails(scope.row)"
            >
              Подробности
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <paginator
        :paginator.sync="paginator"
        @page-changed="getIntegrations"
      ></paginator>
    </div>

    <el-dialog
      destroy-on-close
      title="Подробности"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <div>
        <div>Общее количество обработанных строк: {{currentIntegration.result ? currentIntegration.result.total_rows : 'Нет данных'}}</div>
        <div>Количество строк с ошибками: {{currentIntegration.result ? currentIntegration.result.errors_count : 'Нет данных'}}</div>
        <div
          v-if="currentIntegration.result && currentIntegration.result.errors && currentIntegration.result.errors.length"
        >
          Ошибки:
          <div
            v-for="error in currentIntegration.result.errors"
          >
            {{error}}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import HomeNavigation from "@/components/home/HomeNavigation";
import {mapGetters} from "vuex";
import HomeCharts from "@/componentsCompany/cherkizovo/home/HomeCharts.vue";
import PageHeading from "@/components/PageHeading.vue";
import requestSender from "@/api/base/requestSender";
import CommentsBlock from "@/components/comments/CommentsBlock.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";

export default {
  name: 'Integration',
  components: {Paginator, CommentsBlock, PageHeading, HomeCharts, HomeNavigation},
  mixins:[paginator, sorter],

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      integrations: [],

      showDialog: false,
      currentIntegration: {},
    }
  },
  mounted() {
    this.getIntegrations();
  },
  methods: {
    getIntegrations(){
      this.loading = true;
      requestSender('get', 'cherkizovo-back-integration/get-integrations', {})
        .then(({data, paginator, sorter}) => {
          this.sorterFill(sorter);
          this.paginatorFill(paginator);

          this.integrations = data.integrations;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    showDetails(integration){
      this.currentIntegration = integration;
      this.showDialog = true;
    },
  }
}

</script>

<style lang="scss">

</style>